<script>
export default {
  name: "LightningMap",
  data() {
    return {
      modalVisible: false,
      cacheKey: null,
      trac: null
    };
  },
  methods: {
    toggleModalVisible() {
      this.modalVisible = !this.modalVisible;
    },
    async updateTracReport() {
      this.trac = await fetch("/lightning/TRACReport.txt?rnd=" + this.cacheKey)
        .then(response => { return response.text() })
        .catch(error => console.error(error));
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.cacheKey = +new Date();
      this.updateTracReport();
    }, 120 * 1000);
    this.updateTracReport();
    this.cacheKey = new Date();
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<template>
  <div
    v-show="modalVisible"
    class="modal fade show"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-modal="true"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            @click="toggleModalVisible()"
            class="btn-close"
          ></button>
        </div>
        <div class="modal-body">
          <slot>
            <img :src="'/lightning/thunder.jpg?rnd=' + cacheKey" class="img-fluid mx-auto d-block" />
          </slot>
        </div>
      </div>
    </div>
  </div>

  <ul class="nav nav-tabs" id="idTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="lightning-tab" data-bs-toggle="tab" data-bs-target="#lightning" type="button" role="tab" aria-controls="lightning" aria-selected="true">Salamat kartalla</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="trac-tab" data-bs-toggle="tab" data-bs-target="#trac" type="button" role="tab" aria-controls="trac" aria-selected="false">TRAC-raportti</button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="lightning" role="tabpanel" aria-labelledby="lightning-tab">
      <figure class="figure">
        <img
          :src="'/lightning/thunder.jpg?rnd=' + cacheKey"
          class="figure-img img-fluid rounded"
          @click="toggleModalVisible()"
        />
        <figcaption class="figure-caption">
          Huomaa, että salamapaikannin/ukkostutka ei anna absoluuttisen tarkkaa esitystä salaman sijainnista,
            koska yksittäisen salaman pituus voi olla jopa kymmeniä kilometrejä ja mittalaitteiston erotuskyky on rajallinen.
        </figcaption>
        <a data-bs-toggle="collapse" href="#collapseLightningMapLegend" role="button" aria-expanded="false" aria-controls="collapseLightningMapLegend">
          Kuvan selitykset <span class="bi-chevron-down" />
        </a>
        <div class="collapse" id="collapseLightningMapLegend">
          <b>Strike rate</b> = salamamäärä minuutissa<br/>
          <b>Range</b> = kartan säde havaintopisteestä<br/>
          <b>Mode</b> = esitystapa isku(strike)/tiheys(density)<br/>
          <b>Persistence</b> = iskun näkymisaika kartalla<br/>
          <img src="../assets/img/lightning_symbols.gif" alt="symbolit"/><br/>
          -CG = Negatiivinen maasalama<br/>
          -IC = Negatiivinen pilvisalama<br/>
          +CG = Positiivinen maasalama<br/>
          +IC = Positiivinen pilvisalama<br/>
          <br/>
          Salamansiskua osoittava symboli muuttuu sitä tummemmaksi, mitä vanhemmasta iskusta on kyse. Symboli lakkaa näkymästä kartalla, kun ennalta asetettu näkymisaika (persistence) on kulunut iskun havaitsemisesta.<br/>
          <br/>
          Kartassa on mahdollisesti näkyvissä myös paikantimen antama arvio ukkosalueen sijainnista, joka on merkitty katkoviivoitetulla ympyrällä ja koodinumerolla. Paikannetusta ukkosesta laaditaan myös lyhyt raportti jonka voit avata "TRAC -raportti" välilehdestä.
        </div>
      </figure>
    </div>
    <div class="tab-pane fade" id="trac" role="tabpanel" aria-labelledby="trac-tab">
      <textarea id="tracreport" name="tracreport" disabled v-model="trac" />
    </div>
  </div>
</template>

<style scoped>
#tracreport {
  height: 600px;
  width: 100%;
  position: relative;
  resize: none;
}
</style>
